jQuery(document).ready(function($) {
	var allActiveImgs = $('.bg-tab-slide-single.active .bg-tab-slider-image');

	//Looping through the BG images
	function loopBTSImgs(){
		var BTSTime = 0;
		allActiveImgs.each(function(){
			var thisBTSImg = $(this);
			setTimeout( function(){
				thisBTSImg.fadeIn(400, function(){
					allActiveImgs.not(this).fadeOut();
				});
			}, BTSTime)
			BTSTime += 5000;
		});
	}

	loopBTSImgs();
	var totalBTSTime = 5000 * allActiveImgs.length;
	var BTSinterval = setInterval(loopBTSImgs, totalBTSTime);

	$('.bg-tab-slider-tabs-wrapper button').on('click', function() {
		var tabID = $(this).attr('data-tab');
		// console.log('tab id = ' + tabID);
		// Remove active state from bg images, content sections, & side tab labels
		$('.bg-tab-slide-single').removeClass('active');
		$('.bg-tab-slider-tab-button').removeClass('active');
		// Add active class to bg image, content section & side label matching this id
		$(".bg-tab-slide-single[data-slide='" + tabID + "']").addClass('active');
		$(this).addClass('active');
		clearInterval(BTSinterval); // stop the interval
		allActiveImgs = $('.bg-tab-slide-single.active .bg-tab-slider-image');
		totalBTSTime = 5000 * allActiveImgs.length;
		BTSinterval = setInterval(loopBTSImgs, totalBTSTime);
		loopBTSImgs();
	});

	

}); /* end of as page load scripts */